import { combineReducers } from "@reduxjs/toolkit";
import { api } from "../graphql/generated";
import currentUserReducer from "./currentUserSlice";
import initialRequestReducer from "./initialRequestSlice";
import currentPatientReducer from "./currentPatientSlice";
import currentClinicReducer from "./currentClinicSlice";
import visitReducer from "./visitSlice";
import recordingListReducer from "./recordingListSlice";
import assessmentReducer from "./assessmentSlice";
import authReducer from "./authSlice";
import DeletedMedicineReducer from "./DeletedMedicinesSlice";
import AllergiesBinReducer from "./AllergiesBinSlice";
import binsActiveReducer from "./BinsActiveSlice";
import medicineBinGlossaryReducer from "./MedicineBinSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  currentUser: currentUserReducer,
  initialRequest: initialRequestReducer,
  visit: visitReducer,
  recordingList: recordingListReducer,
  currentPatient: currentPatientReducer,
  assessment: assessmentReducer,
  currentClinic: currentClinicReducer,
  deletedMedicines: DeletedMedicineReducer,
  allergiesBin: AllergiesBinReducer,
  binsActive: binsActiveReducer,
  MedicineBinGlossary: medicineBinGlossaryReducer,
  [api.reducerPath]: api.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
