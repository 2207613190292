import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetMedicineGlossaryByTradeName, GetMedicineGlossaryBySaltName } from '../utils/allergies';

// Async thunks for fetching glossaries
export const fetchTradeNameGlossary = createAsyncThunk(
  'glossaries/fetchTradeNameGlossary',
  async () => {
    const response = await GetMedicineGlossaryByTradeName();
    return response;
  }
);

export const fetchSaltNameGlossary = createAsyncThunk(
  'glossaries/fetchSaltNameGlossary',
  async () => {
    const response = await GetMedicineGlossaryBySaltName();
    return response;
  }
);

// Initial state
const initialState = {
  TradeNameGlossary: null as any | null,
  SaltNameGlossary: null as any | null,
  loading: false,
  error: null as string | null,
};

// Redux slice
const MedicineglossarySlice = createSlice({
  name: 'glossaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradeNameGlossary.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTradeNameGlossary.fulfilled, (state, action) => {
        state.TradeNameGlossary = action.payload;
        state.loading = false;
      })
      .addCase(fetchTradeNameGlossary.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch TradeNameGlossary';
        state.loading = false;
      })
      .addCase(fetchSaltNameGlossary.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSaltNameGlossary.fulfilled, (state, action) => {
        state.SaltNameGlossary = action.payload;
        state.loading = false;
      })
      .addCase(fetchSaltNameGlossary.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch SaltNameGlossary';
        state.loading = false;
      });
  },
});

export default MedicineglossarySlice.reducer;
