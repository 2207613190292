import * as Papa from "papaparse";

export const fetchCSV = async () => {
  const response = await fetch("/FormattedSheet.csv");
  const csvText = await response.text();
  const salts: string[] = [];
  // Parse the CSV file
  Papa.parse(csvText, {
    header: true,
    skipEmptyLines: true,
    complete: (result: any) => {
      const data = result.data;
      salts.push(
        ...data
          .map((row: any) => row["SALT NAME"])
          .filter((salt: string | undefined) => salt?.trim())
      );
    },
  });

  const uniqueSalts = Array.from(new Set(salts));

  return uniqueSalts;
};

// Hassan: Making a glossary twice, first with all tradenames as key and then with saltnames as key. values will be json objects with keys Trade_Name, Class, Salt, Number, Unit,Route, Frequency, Note
interface MedicineCSVReturn {
  Trade_Name: string,
  Class: string ,
  Salt: string ,
  Number: string  ,
  Unit: string ,
  Route: string  ,
  Frequency: string ,
  Notes: string ,
}
export const GetMedicineGlossaryByTradeName = async () => {
  const response = await fetch("/FormattedSheet.csv");
  const csvText = await response.text();

  const renameCols = {
    "TRADE/BRAND NAME": "Trade_Name",
    "CLASS": "Class",
    "SALT NAME": "Salt",
    "DOSES": "Number",
    "UNITS": "Unit",
    "ROUTES": "Route",
    "FREQUENCY FORMATTED": "Frequency",
    "NOTES": "Notes",
  };

  const TradeNameGlossary: Record<string, MedicineCSVReturn> = {};

  // Parse the CSV file
  Papa.parse(csvText, {
    header: true,
    skipEmptyLines: true,
    complete: (result: any) => {
      const data = result.data;

      data.forEach((row: any) => {
        const mappedRow: MedicineCSVReturn = {
          Trade_Name: row["TRADE/BRAND NAME"]?.trim() || "",
          Class: row["CLASS"]?.trim() || "",
          Salt: row["SALT NAME"]?.trim() || "",
          Number: row["DOSES"]?.trim() || "",
          Unit: row["UNITS"]?.trim() || "",
          Route: row["ROUTES"]?.trim() || "",
          Frequency: row["FREQUENCY FORMATTED"]?.trim() || "",
          Notes: row["NOTES"]?.trim() || "",
        };

        // Skip rows with empty trade names
        if (!mappedRow.Trade_Name) {
          return;
        }

        // Check if the trade name already exists in the glossary
        if (TradeNameGlossary[mappedRow.Trade_Name]) {
          const existingEntry = TradeNameGlossary[mappedRow.Trade_Name];

          // Append new values if they are not empty or undefined
          existingEntry.Class = appendNonEmpty(existingEntry.Class, mappedRow.Class);
          existingEntry.Salt = appendNonEmpty(existingEntry.Salt, mappedRow.Salt);
          existingEntry.Number = appendNonEmpty(existingEntry.Number, mappedRow.Number);
          existingEntry.Unit = appendNonEmpty(existingEntry.Unit, mappedRow.Unit);
          existingEntry.Route = appendNonEmpty(existingEntry.Route, mappedRow.Route);
          existingEntry.Frequency = appendNonEmpty(existingEntry.Frequency, mappedRow.Frequency);
          existingEntry.Notes = appendNonEmpty(existingEntry.Notes, mappedRow.Notes);
        } else {
          // Add a new entry to the glossary
          TradeNameGlossary[mappedRow.Trade_Name] = mappedRow;
        }
      });
    },
  });

  return TradeNameGlossary;
};

export const GetMedicineGlossaryBySaltName = async () => {
  const response = await fetch("/FormattedSheet.csv");
  const csvText = await response.text();

  const renameCols = {
    "TRADE/BRAND NAME": "Trade_Name",
    "CLASS": "Class",
    "SALT NAME": "Salt",
    "DOSES": "Number",
    "UNITS": "Unit",
    "ROUTES": "Route",
    "FREQUENCY FORMATTED": "Frequency",
    "NOTES": "Notes",
  };

  const SaltNameGlossary: Record<string, MedicineCSVReturn> = {};

  // Parse the CSV file
  Papa.parse(csvText, {
    header: true,
    skipEmptyLines: true,
    complete: (result: any) => {
      const data = result.data;

      data.forEach((row: any) => {
        const mappedRow: MedicineCSVReturn = {
          Trade_Name: row["TRADE/BRAND NAME"]?.trim() || "",
          Class: row["CLASS"]?.trim() || "",
          Salt: row["SALT NAME"]?.trim() || "",
          Number: row["DOSES"]?.trim() || "",
          Unit: row["UNITS"]?.trim() || "",
          Route: row["ROUTES"]?.trim() || "",
          Frequency: row["FREQUENCY FORMATTED"]?.trim() || "",
          Notes: row["NOTES"]?.trim() || "",
        };

        // Skip rows with empty trade names
        if (!mappedRow.Salt) {
          return;
        }

        // Check if the trade name already exists in the glossary
        if (SaltNameGlossary[mappedRow.Salt]) {
          const existingEntry = SaltNameGlossary[mappedRow.Salt];

          // Append new values if they are not empty or undefined
          existingEntry.Class = appendNonEmpty(existingEntry.Class, mappedRow.Class);
          existingEntry.Salt = appendNonEmpty(existingEntry.Salt, mappedRow.Salt);
          existingEntry.Number = appendNonEmpty(existingEntry.Number, mappedRow.Number);
          existingEntry.Unit = appendNonEmpty(existingEntry.Unit, mappedRow.Unit);
          existingEntry.Route = appendNonEmpty(existingEntry.Route, mappedRow.Route);
          existingEntry.Frequency = appendNonEmpty(existingEntry.Frequency, mappedRow.Frequency);
          existingEntry.Notes = appendNonEmpty(existingEntry.Notes, mappedRow.Notes);
        } else {
          // Add a new entry to the glossary
          SaltNameGlossary[mappedRow.Salt] = mappedRow;
        }
      });
    },
  });

  return SaltNameGlossary;
};

// Helper function to append non-empty values
const appendNonEmpty = (existingValue: string, newValue: string): string => {
  if (!newValue) {
    return existingValue;
   } // If new value is empty, return existing value
  if (!existingValue) {
    return newValue;
   } // If no existing value, use the new value
  return `${existingValue}, ${newValue}`; // Append the new value to the existing value
}

interface AllergyCSVRow {
  Allergen: string;
  "Allergen Class": string;
  Reaction: string;
  Categorization: string;
}

export const assignCategoriesToAllergies = async (
  allergies: any[]
): Promise<any[]> => {
  try {
    // Fetch and parse the CSV file
    const fetchCSV = async (filePath: string): Promise<AllergyCSVRow[]> => {
      const response = await fetch(filePath);
      const csvText = await response.text();

      return new Promise((resolve, reject) => {
        Papa.parse<AllergyCSVRow>(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result: any) => {
            if (result.errors.length) {
              reject(result.errors);
            } else {
              resolve(result.data);
            }
          },
        });
      });
    };

    const csvData = await fetchCSV("/AllergiesSheet.csv");

    // Update the allergies array with the appropriate category
    const updatedAllergies = allergies.map((allergy) => {
      const allergenClass = allergy.Allergen_Class;
      const reaction = allergy.Reaction[0]; // Take the first reaction

      const matchingRow = csvData.find((row) => {
        // Find the matching row in the CSV file
        const csvAllergenClasses = row["Allergen Class"]
          ?.toLowerCase()
          .split(",")
          .map((cls: any) => cls.trim());
        return (
          csvAllergenClasses?.includes(allergenClass?.toLowerCase()) &&
          row.Reaction.toLowerCase() === reaction?.toLowerCase()
        );
      });

      return {
        ...allergy,
        Category: matchingRow ? matchingRow.Categorization : allergy.Category,
      };
    });

    return updatedAllergies;
  } catch (error) {
    console.error("Error while assigning categories to allergies:", error);
    throw error;
  }
};
export const areRequiredAttributesPresent = (
  object: any,
  requiredKeys: string[] = ["Allergen", "Reaction", "Category", "Start_date"]
) => {
  return requiredKeys.every((key: any) => {
    const value = object[key];
    return value !== null && value !== undefined && value !== "";
  });
};
